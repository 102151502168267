import * as React from "react"
import { graphql } from "gatsby"

import TextPage from "../components/TextPage";

const NotFoundPage = ({ data, location }) => {
  return (
    <TextPage heading="404 Not Found">
        <h3 style={{color: "#E91E63"}}> Diese Seite existiert nicht!</h3>
    </TextPage>
  )
}

export default NotFoundPage

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`
